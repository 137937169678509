import { useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  useGetVideosQuery as useGetVideosQueryFunction,
  useGetAudiosQuery as useGetAudiosQueryFunction,
  useGetVideosUpscaleAllQuery,
  useReloadVideosUpscaleQuery,
} from "../../API";
import { APIClient } from "../../utils/services";
import { useAuth } from "../../AuthProvider";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import ReactPlayer from "react-player";
import PopUp from "../SharedComponents/PopUp";
import "./styles.css";
import {
  useAdvancedSettings,
  useAnimationModeStore,
  useArtStyle,
  useAspectRatioStore,
  useAudioAsset,
  useAudioTrim,
  useAvatar,
  useStartingFrame,
  useSubjectMatter,
  useTransitions,
  useZoomPulseStore,
} from "../../store/hooks";
import toast from "react-hot-toast";
import PreviewModalVideo from "../PreviewVideoModal/PreviewModalVideo";
import { OverlayTrigger, ProgressBar, Toast, Tooltip } from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import PreviewModalSocialMedia from "../PreviewSocialMedia/PreviewModalSocialMedia";

const BASE_URL = process.env.REACT_APP_S3_BUCKET_UPSCALE_VIDEOS_PUBLIC;
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
mixpanel.init(MIXPANEL_TOKEN);

export default function Projects() {
  const [show, setShow] = useState(-1);
  const [projects, setProjects] = useState<any[]>([]);
  const [musics, setMusics] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showShareSocialMedia, setShowShareSocialMedia] = useState(false);
  const [showLoadingCard, setShowLoadingCard] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVideoId, setPreviewVideoId] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [deleteProjectId, setDeleteProjectId] = useState("");
  const [upscaledVideos, setUpscaledVideos] = useState<any[]>([]);
  const n = useNavigate();

  const paginator = usePaginator();
  const { setTotal, setItemsPerPage, start, end } = paginator;

  const [search] = useSearchParams();
  const query = search.get("query");

  // Redux setters
  const { setUserVideoName, setUserInitialImageKey, setUserSubjectMatter } =
    useStartingFrame();
  const { setAspectRatio } = useAspectRatioStore();
  const { setAnimationMode } = useAnimationModeStore();
  const { setUserThemeId } = useArtStyle();
  const { setIsFromLibrary, setMusic } = useAudioAsset();
  const { setStart, setEnd } = useAudioTrim();
  const { setZoomPulse } = useZoomPulseStore();
  const { setTransit } = useTransitions();
  const { setAvatar } = useAvatar();
  const { setAdvSettings } = useAdvancedSettings();
  const { setSubjectMatter } = useSubjectMatter();
  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;

  const { data, isSuccess, isLoading, refetch } = useGetVideosQueryFunction({
    ownerId,
  });

  const { data: dataUpscaleQuery } = useReloadVideosUpscaleQuery({
    ownerId,
  });

  const {
    data: audiosData,
    isSuccess: audioIsSuccess,
    refetch: audioIsRefetch,
  } = useGetAudiosQueryFunction({
    ownerId,
  });

  const { data: dataUpscale } = useGetVideosUpscaleAllQuery({
    ownerId,
  });

  useEffect(() => {
    const a = audiosData?.results.items || [];
    if (a.length > 0) {
      const filteredMusics = a.filter((i: any) => i.next_step_video === true);
      const newMusics = filteredMusics.map((i: any) => {
        return {
          nextStepVideo: i.next_step_video === true,
          status_OK: i.AUDIO_status === "DONE",
          errorTimeOut: i.AUDIO_status === "ERROR-States.Timeout",
          error: i.AUDIO_status === "ERROR-States.TaskFailed",
        };
      });

      setMusics(newMusics);

      const filteredMusicsWithConditions = newMusics.filter(
        (x: any) =>
          x.nextStepVideo !== x.status_OK &&
          x.errorTimeOut === false &&
          x.error === false
      );

      const loadingCards = filteredMusicsWithConditions.map(
        (music: any, index: number) => (
          <LoadingCard key={index} {...music} index={index} musics={musics} />
        )
      );

      setMusics(loadingCards);

      const hasNextStepVideoAndStatusNotOK = newMusics.some(
        (music: any) => music.nextStepVideo !== music.status_OK
      );

      const isErrorTimeOut = newMusics.some((music: any) => music.errorTimeOut);

      const isError = newMusics.some((music: any) => music.error);

      setShowLoadingCard(
        hasNextStepVideoAndStatusNotOK && !isError && isErrorTimeOut
      );

      audioIsRefetch();
    }
  }, [audiosData, query, showLoadingCard]);

  const onDelete = (id: string) => {
    setDeleteProjectId(id);
    setShowModal(true);
  };

  const onShowPreviewModal = (id: string, url: string, title: string) => {
    setShowPreviewModal(true);
    setPreviewUrl(url);
    setPreviewTitle(title);
    setPreviewVideoId(id);
  };

  const onShowShareSocialMedia = (url: string, title: string) => {
    setShowPreviewModal(false);
    setShowShareSocialMedia(true);
    setPreviewUrl(url);
    setPreviewTitle(title);
  };

  const hasProjects = projects.length > 0;

  const copyVideo = (videoId: string) => {
    const client = new APIClient();
    client.authenticate(
      undefined,
      undefined,
      process.env.REACT_APP_MUZE_API_KEY
    );
    client.getVideoGenerationParams(videoId, ownerId).then((states: any) => {
      console.log("copy ->", states);
      if (states.startingFrame) {
        const {
          startingFrame,
          generateVideo,
          audioAsset,
          artStyles,
          subjectsMatter,
        } = states;
        let [first, ...aspectRatio] = generateVideo.aspectRatio.split("");
        const aspectRatioCaptalized = [
          first.toUpperCase(),
          ...aspectRatio,
        ].join("");

        setUserVideoName(startingFrame?.videoName);
        setAspectRatio(aspectRatioCaptalized);
        setAnimationMode(generateVideo.animationMode);
        setUserThemeId(artStyles.themeId);
        setUserInitialImageKey(startingFrame?.initialImageKey);
        setIsFromLibrary();
        setMusic({ asset_id: audioAsset.selected });
        setStart(audioAsset.start);
        setEnd(audioAsset.end);
        setZoomPulse(generateVideo.zoomPulse);
        setTransit(generateVideo.transitions);
        setUserSubjectMatter(subjectsMatter?.subjectMatter);
        setSubjectMatter(
          subjectsMatter?.subjectMatter?.map((subject: any, index: any) => ({
            label: subject,
            time: subjectsMatter?.time?.[index],
            negativePrompt: subjectsMatter?.negativePrompt?.[index],
          }))
        );

        setAdvSettings(generateVideo.videoConfig);
        setAvatar({ id: artStyles.userAvatar });
      }

      if (
        states.startingFrame?.initialImageKey === null ||
        states.startingFrame?.initialImageKey === undefined
      ) {
        n("/app/create-video/audio-select", {
          state: { copyInfo: states },
        });
        return;
      }

      n("/app/image-to-video/image-select", {
        state: { copyInfo: states },
      });
    });
  };

  useEffect(() => {
    setTotal(projects.length);
    setItemsPerPage(12);
  }, [projects, setItemsPerPage, setTotal]);

  useEffect(() => {
    if (dataUpscale) {
      const upscaleData = dataUpscale.result || [];
      const videos = upscaleData.map((video: any) => ({
        idUps: video.video_id,
        statusUps: video.UPSCALE_status,
        okUps: video.UPSCALE_status === "DONE",
        errorUps: video.UPSCALE_status === "ERROR",
        progressUps: mapStatusToProgressUps(video.UPSCALE_status),
        dateUps: new Date(video.created_at).toLocaleDateString(),
        lastUpdateUps: new Date(video.created_at),
      }));
      setUpscaledVideos(videos);
    }
  }, [dataUpscale]);

  useEffect(() => {
    const p = data?.result || [];
    if (upscaledVideos.length > 0 || p.length > 0) {
      const updatedProjects = p.map((i: any) => {
        const correspondingUpscaleItem = dataUpscaleQuery?.contents?.find(
          (upscaleItem: any) => upscaleItem.id_upscale === i.video_id
        );

        const getEmailEncodedKey = (key: any) => {
          const [email, videoId] = key.split("/");
          const encodedEmail = encodeURIComponent(email);
          return `${encodedEmail}/${videoId}`;
        };

        const url =
          correspondingUpscaleItem?.id_upscale === i.video_id
            ? BASE_URL +
              getEmailEncodedKey(correspondingUpscaleItem.Key)
                .split(" ")
                .join("+")
            : i.video_url;

        const isUpscaleDone =
          correspondingUpscaleItem?.id_upscale === i.video_id;

        const lastUpdate = new Date(i.last_update);
        const now = new Date();

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const nowLocal = new Date(
          now.toLocaleString("en-US", { timeZone: userTimeZone })
        );
        const lastUpdateLocal = new Date(
          lastUpdate.toLocaleString("en-US", { timeZone: userTimeZone })
        );

        const differenceInMiliseconds =
          nowLocal.getTime() - lastUpdateLocal.getTime();
        const differenceInHours = differenceInMiliseconds / (1000 * 60 * 60);
        const errorTimeOutVideo = 4;

        return {
          id: i.video_id,
          okUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)?.okUps,
          idUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)?.idUps,
          statusUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
            ?.statusUps,
          dateUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
            ?.dateUps,
          progressUps: upscaledVideos.find((v: any) => v.idUps === i.video_id)
            ?.progressUps,
          name: i.video_name,
          isError: i.user_status === "ERROR",
          progress: mapStatusToProgress(i.video_status),
          frameProgress: i.progress,
          date: new Date(i.last_update).toLocaleDateString(),
          lastUpdate: new Date(i.last_update),
          forceSpinner:
            i.video_status === "STABLE_DIFFUSION_INIT_START" ||
            i.video_status === "MOTION_COMPENSATION_END",
          statusVideo: i.video_status,
          stateMachine: i.StateMachineArn,
          status: i.user_status,
          statusUpscale: "UPSCALING IN PROGRESS",
          statusUpscaleError: "ERROR IN UPSCALING PROCESS",
          statusUpscaleDone: isUpscaleDone ? "UPSCALING DONE" : undefined,
          statusTimeOut: "TIMEOUT ERROR",
          statusAbortedError: "VIDEO GENERATION CANCELLED",
          ok: i.user_status === "DONE",
          abortedError: i.video_status === "ABORTED",
          icon:
            i.user_status === "DONE"
              ? "file-earmark-play"
              : i.user_status == "PROCESSING" &&
                differenceInHours > errorTimeOutVideo
              ? "bi bi-exclamation-triangle-fill"
              : i.video_status === "ABORTED"
              ? "bi bi-x-octagon-fill"
              : "exclamation-octagon",
          url: url,
          errorTimeOut:
            i.user_status == "PROCESSING" &&
            differenceInHours > errorTimeOutVideo,
          show,
          setShow,
          onDelete,
          onShowPreviewModal,
          onShowShareSocialMedia,
          copyVideo: () => copyVideo(i.video_id),
        };
      });
      setProjects(
        updatedProjects
          .filter(
            (i: any) =>
              !query ||
              query === "" ||
              i.name.toLowerCase().includes(query.toLowerCase()) ||
              i.date.includes(query) ||
              i.status.toLowerCase().includes(query)
          )
          .sort((a: any, b: any) => b.lastUpdate - a.lastUpdate) // newes first
          .map((j: any, k: number, l: any) => (
            <Card key={k} {...{ ...j, k, l }} />
          ))
      );
      refetch();
    }
  }, [data, upscaledVideos, show, query]);

  const modal = {
    title: "Delete Project",
    text: "Are you sure you want to delete this project?",
    confirmText: "Confirm",
    shouldShow: showModal,
    onConfirm: () => {
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );
      console.log("delete Project: ", deleteProjectId);
      client.project_delete(ownerId, deleteProjectId, () => refetch());
      setDeleteProjectId("");
      setShowModal(false);
      setShow(-1);
    },
    onClose: () => {
      setDeleteProjectId("");
      setShowModal(false);
    },
  };

  const previewModal = {
    videoId: previewVideoId,
    title: previewTitle,
    shouldShow: showPreviewModal,
    url: previewUrl,
    onClose: () => {
      setShowPreviewModal(false);
    },
    download: download,
  };

  const previewModalMedias = {
    title: previewTitle,
    shouldShow: showShareSocialMedia,
    url: previewUrl,
    onClose: () => {
      setShowShareSocialMedia(false);
      setShowPreviewModal(false);
    },
  };

  const p = projects.length > 10 ? projects.slice(start, end) : projects;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div
        className="d-flex flex-wrap align-items-start justify-content-start p-3 gap-3"
        style={{ width: "-webkit-fill-available" }}
      >
        {start === 0 && musics.length > 0 && musics}
        {isLoading && <Spinner />}
        {isSuccess && !hasProjects && !showLoadingCard && "0 projects found."}
        {isMobileDevice() ? (
          <div className="d-flex flex-wrap justify-content-center">
            {isSuccess && hasProjects && p}
          </div>
        ) : (
          isSuccess && hasProjects && p
        )}
      </div>
      <PopUp {...modal} />
      <PreviewModalSocialMedia {...previewModalMedias} />
      {!showShareSocialMedia && <PreviewModalVideo {...previewModal} />}
      <Paginator {...paginator} />
    </div>
  );
}

function Card(props: any) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;

  const {
    id,
    name,
    date,
    dateUps,
    status,
    ok,
    icon,
    url,
    k,
    l,
    show,
    setShow,
    errorTimeOut,
    statusTimeOut,
    abortedError,
    progress,
    onDelete,
    statusVideo,
    isError,
    onShowPreviewModal,
    copyVideo,
    idUps,
    statusUps,
    progressUps,
    okUps,
    statusUpscale,
    stateMachine,
    statusUpscaleDone,
    statusUpscaleError,
    statusAbortedError,
    frameProgress,
  } = props;

  const [currentFrameData, setCurrentFrameData] = useState({
    frameIndex: null,
    maxFrames: null,
    frameUrl: "",
  });

  useEffect(() => {
    if (frameProgress) {
      const { saved_frame_index, saved_frame_httplink, max_frames } =
        frameProgress;
      setCurrentFrameData({
        frameIndex: saved_frame_index,
        maxFrames: max_frames,
        frameUrl: saved_frame_httplink,
      });
    }
  }, [frameProgress]);

  const showPlayer = show === k;
  const hide = show > -1 && !showPlayer;
  const closePlayer = () => setShow(-1);
  const ref = useRef<any>(null);
  const [playing, setPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const processing = status === "PROCESSING" && !errorTimeOut && !abortedError;
  const errorTime = errorTimeOut;
  const showUpscaleProgressBar = idUps && statusUps !== "ERROR" && !okUps;
  const unshowUpscaleProgressBar = idUps && statusUps === "ERROR" && !okUps;
  const sharing = true;

  const last = `${ok ? "btn-dark" : ""} ${k === l.length - 1 ? "me-auto" : ""}`;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const actionButton = isMobileDevice()
    ? {
        icon: "share",
        tooltip: "Share",
        onClick: () => {
          shareMedias(id, navigate, url, name, ownerId, stateMachine, sharing, {
            ok: ok,
          });
        },
      }
    : {
        icon: "download",
        tooltip: "Download",
        onClick: () => {
          download(url);
        },
      };

  let commands: Control[] = [];

  if (showUpscaleProgressBar) {
    commands = [];
  } else if (errorTime) {
    commands = [
      {
        icon: "trash",
        tooltip: "Delete",
        onClick: (e: any) => {
          e.stopPropagation();
          onDelete(id);
        },
      },
      {
        icon: "files",
        tooltip: "Copy",
        onClick: copyVideo,
      },
    ];
  } else if (isError) {
    commands = [
      {
        icon: "trash",
        tooltip: "Delete",
        onClick: (e: any) => {
          e.stopPropagation();
          onDelete(id);
        },
      },
      {
        icon: "files",
        tooltip: "Copy",
        onClick: copyVideo,
      },
    ];
  } else if (abortedError) {
    commands = [
      {
        icon: "trash",
        tooltip: "Delete",
        onClick: (e: any) => {
          e.stopPropagation();
          onDelete(id);
        },
      },
      {
        icon: "files",
        tooltip: "Copy",
        onClick: copyVideo,
      },
    ];
  } else {
    commands = [
      {
        icon: "trash",
        tooltip: "Delete",
        onClick: (e: any) => {
          e.stopPropagation();
          onDelete(id);
        },
      },
      {
        icon: "display",
        tooltip: "Upscale",
        onClick: () => {
          upScale(id, navigate, url, name, ownerId, stateMachine, { ok: ok });
        },
      },
      { icon: "files", tooltip: "Copy", onClick: copyVideo },
      actionButton,
    ];
  }

  const handleCardClick = () => {
    if (abortedError) {
      return;
    }

    if (!showUpscaleProgressBar) {
      if (showPlayer) {
        setPlaying(!playing);
      } else {
        if (ok) {
          onShowPreviewModal(id, url, name);
        } else {
          upScale(id, navigate, url, name, ownerId, stateMachine, { ok: ok });
        }
      }
    }
  };

  const handleCardClickMobile = () => {
    if (abortedError) {
      return;
    }

    if (!showUpscaleProgressBar) {
      if (showPlayer) {
        setPlaying(!playing);
      } else {
        if (ok) {
          upScale(id, navigate, url, name, ownerId, stateMachine, { ok: ok });
        } else {
          upScale(id, navigate, url, name, ownerId, stateMachine, { ok: ok });
        }
      }
    }
  };

  const player = showPlayer ? "player-open" : "player-closed";
  const display = hide ? "d-none" : "d-flex";

  const frameIndex = currentFrameData.frameIndex ?? 0;
  const maxFrames = currentFrameData.maxFrames ?? 16;

  const progressBarPercentage = (frameIndex / maxFrames) * 100;

  const statusGranularVideo =
    currentFrameData.frameIndex !== undefined &&
    currentFrameData.maxFrames !== undefined
      ? `${progressBarPercentage.toFixed(0)}%`
      : statusVideo;

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={`${display} flex-column rounded border text-center p-1 btn ${last} ${player} ${
            statusUpscaleDone ? "status-upscale-done-border" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleCardClickMobile();
          }}
          style={{
            width: "100%",
            marginBottom: "0.5rem",
            marginRight: "10px",
            maxWidth: "40%",
          }}
        >
          {/* <p className="m-0 p-0 small">{date}</p> */}
          {showUpscaleProgressBar && !unshowUpscaleProgressBar && (
            <ProgressBar
              striped
              animated
              variant="info"
              max={16}
              now={progressUps}
              label={statusUps}
              style={{ width: "100%" }}
            />
          )}
          <div className="d-flex align-items-center">
            {!ok && !processing && (
              <div className="d-flex flex-grow-1 align-items-center justify-content-start flex-column">
                <div
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{ width: "100px", height: "100px" }}
                >
                  <StatusIcon
                    {...{ icon }}
                    style={{ height: "100%", width: "auto" }}
                  />
                </div>
                <div className="d-flex">
                  {commands.map((i: Control, k: number) => (
                    <OverlayTrigger
                      key={i.tooltip}
                      placement="right"
                      overlay={<Tooltip id={i.tooltip}>{i.tooltip}</Tooltip>}
                    >
                      <div
                        onClick={(e: any) => i.onClick(e)}
                        key={k}
                        className="command mx-1 my-1"
                        style={{ fontSize: "xx-small" }}
                      >
                        <i className={`bi bi-${i.icon} p-0 m-0`} />
                      </div>
                    </OverlayTrigger>
                  ))}
                </div>
              </div>
            )}
          </div>
          {processing && !errorTimeOut && !abortedError && <Spinner />}

          {ok && (
            <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-hidden">
              <div
                className="d-flex"
                style={{
                  position: "relative",
                  width: "120px",
                  height: "160px",
                }}
              >
                <ReactPlayer
                  {...{
                    url,
                    height: isLoading ? "1px" : "100%",
                    width: "100%",
                    ref,
                    playing,
                    loop: true,
                    muted: false,
                    volume: 0.8,
                    controls: showPlayer,
                    onReady: () => setIsLoading(false),
                  }}
                />
                {!isLoading && <Controls {...{ commands }} />}
              </div>
              {isLoading && <Spinner />}
            </div>
          )}
          <p className="text-center m-0 p-0" style={projectTitleStyleMobile}>
            {name}
          </p>
          <p className="m-0 p-0" style={{ fontSize: "0.45rem" }}>
            {errorTimeOut
              ? statusTimeOut
              : abortedError
              ? statusAbortedError
              : showUpscaleProgressBar
              ? statusUpscale
              : okUps
              ? statusUpscaleDone
              : unshowUpscaleProgressBar
              ? statusUpscaleError
              : status}
          </p>
          {!ok && !errorTimeOut && !isError && !abortedError && (
            <ProgressBar
              striped
              animated
              variant="success"
              max={16}
              now={progress}
              label={statusVideo}
              style={{ width: "100%" }}
            />
          )}
        </div>
      ) : (
        <div
          className={`${display} flex-column rounded border text-center p-1 btn ${last} ${player} ${
            statusUpscaleDone ? "status-upscale-done-border" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleCardClick();
          }}
        >
          {!idUps && <p className="m-0 p-0 small">{date}</p>}
          {idUps && <p className="m-0 p-0 small">{dateUps}</p>}
          {showUpscaleProgressBar && !unshowUpscaleProgressBar && (
            <ProgressBar
              striped
              animated
              variant="info"
              max={16}
              now={progressUps}
              label={statusUps}
              style={{ width: "100%" }}
            />
          )}
          <div className="d-flex align-items-center mt-2">
            {!ok && !processing && (
              <div className="d-flex flex-grow-1 align-items-center justify-content-start flex-column">
                <div
                  className="w-100 mb-1 mb-1 d-flex align-items-center justify-content-center"
                  style={{ width: "140px", height: "140px" }}
                >
                  <StatusIcon
                    {...{ icon }}
                    style={{ height: "100%", width: "auto" }}
                  />
                </div>
                <div className="d-flex">
                  {commands.map((i: Control, k: number) => (
                    <OverlayTrigger
                      key={i.tooltip}
                      placement="left"
                      overlay={<Tooltip id={i.tooltip}>{i.tooltip}</Tooltip>}
                    >
                      <div
                        onClick={(e: any) => i.onClick(e)}
                        key={k}
                        className="command mx-1 my-1"
                      >
                        <i className={`bi bi-${i.icon} p-0 m-0`} />
                      </div>
                    </OverlayTrigger>
                  ))}
                </div>
              </div>
            )}
          </div>
          {processing && !errorTimeOut && !abortedError && <Spinner />}
          {ok && (
            <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center overflow-hidden">
              <div
                className="d-flex"
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <ReactPlayer
                  {...{
                    url,
                    height: isLoading ? "1px" : "100%",
                    width: "100%",
                    ref,
                    playing,
                    loop: true,
                    muted: false,
                    volume: 0.8,
                    controls: showPlayer,
                    onReady: () => setIsLoading(false),
                  }}
                />
                {!isLoading && <Controls {...{ commands }} />}
              </div>
              {isLoading && <Spinner />}
            </div>
          )}
          <p className="text-center m-0 p-0" style={projectTitleStyle}>
            {name}
          </p>
          <p className="m-0 p-0" style={{ fontSize: "0.65rem" }}>
            {errorTimeOut
              ? statusTimeOut
              : abortedError
              ? statusAbortedError
              : showUpscaleProgressBar
              ? statusUpscale
              : okUps
              ? statusUpscaleDone
              : unshowUpscaleProgressBar
              ? statusUpscaleError
              : status}
          </p>
          {!ok && !errorTimeOut && !isError && !abortedError && (
            <ProgressBar
              striped
              animated
              variant="success"
              max={
                currentFrameData.maxFrames === undefined
                  ? 16
                  : currentFrameData.maxFrames || 16
              }
              now={
                currentFrameData.frameIndex === undefined
                  ? progress
                  : currentFrameData.frameIndex || progress
              }
              label={statusGranularVideo}
              style={{ width: "100%", height: "29px", fontWeight: "bold" }}
            />
          )}
        </div>
      )}
    </>
  );
}

async function upScale(
  video_asset_id: string,
  navigate: Function,
  url: string,
  name: string,
  owner_Id: string,
  state_machine: string | null,
  options: { ok: boolean }
) {
  try {
    navigate("/app/image-to-video/preview-video", {
      state: {
        videoInfo: {
          url,
          name,
          ownerId: owner_Id,
          id: video_asset_id,
          ok: options.ok,
          stateMachine: state_machine,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}

async function shareMedias(
  video_asset_id: string,
  navigate: Function,
  url: string,
  name: string,
  owner_Id: string,
  state_machine: string | null,
  sharing: boolean,
  options: { ok: boolean }
) {
  try {
    navigate("/app/image-to-video/preview-video", {
      state: {
        videoInfo: {
          url,
          name,
          ownerId: owner_Id,
          id: video_asset_id,
          ok: options.ok,
          stateMachine: state_machine,
          sharing: true,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function mapStatusToProgress(status: any) {
  switch (status) {
    case "VIDEO_BUILD_END":
      return 15;
    case "VIDEO_BUILD_START":
      return 14;
    case "STABLE_DIFFUSION_RENDER_END":
      return 13;
    case "STABLE_DIFFUSION_RENDER_START":
      return 12;
    case "STABLE_DIFFUSION_INIT_END":
      return 11;
    case "STABLE_DIFFUSION_INIT_START":
      return 10;
    case "MOTION_COMPENSATION_END":
      return 9;
    case "MOTION_COMPENSATION_START":
      return 8;
    case "END_TRANSITIONS":
      return 7;
    case "START_TRANSITIONS":
      return 6;
    case "ADDED_NEW_AUDIO_EFFECTS_DONE":
      return 5;
    case "PROMPTS_GENERATED_DONE":
      return 4;
    case "PROMPTS_GENERATED_START":
      return 3;
    case "RESOLVED_AUDIO_ASSET":
      return 2;
    case "SUBMISSION":
      return 0;
    default:
      return 5;
  }
}

function mapStatusToProgressUps(status: any) {
  switch (status) {
    case "DONE":
      return 16;
    case "IN PROGRESS":
      return 12;
    case "SUBMITTED":
      return 8;
  }
}

type Control = { icon: string; tooltip: string; onClick: Function };

function Controls(props: any) {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const { commands } = props;
  let c: Control[] = commands || [];

  return (
    <>
      {isMobileDevice() ? (
        <div
          className="d-flex flex-column justify-content-evenly"
          style={{
            position: "absolute",
            right: "0.5rem",
            zIndex: 1,
            height: "100%",
          }}
        >
          {c.map((i: Control, k: number) => (
            <OverlayTrigger
              key={i.tooltip}
              placement="left"
              overlay={<Tooltip id={i.tooltip}>{i.tooltip}</Tooltip>}
            >
              <div
                onClick={(e: any) => i.onClick(e)}
                key={k}
                className="command"
                style={{ fontSize: "large" }}
              >
                <i className={`bi bi-${i.icon} p-0 m-0`} />
              </div>
            </OverlayTrigger>
          ))}
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-evenly"
          style={{
            position: "absolute",
            right: "0.5rem",
            zIndex: 1,
            height: "100%",
          }}
        >
          {c.map((i: Control, k: number) => (
            <OverlayTrigger
              key={i.tooltip}
              placement="left"
              overlay={<Tooltip id={i.tooltip}>{i.tooltip}</Tooltip>}
            >
              <div
                onClick={(e: any) => i.onClick(e)}
                key={k}
                className="command"
              >
                <i className={`bi bi-${i.icon} p-0 m-0`} />
              </div>
            </OverlayTrigger>
          ))}
        </div>
      )}
    </>
  );
}

function StatusIcon(props: any) {
  const { icon } = props;
  return <i className={`bi bi-${icon} my-auto`} style={{ fontSize: "4rem" }} />;
}

function Spinner() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{
          width: isMobileDevice() ? "2rem" : "3rem",
          height: isMobileDevice() ? "2rem" : "3rem",
        }}
        role="status"
      />
    </div>
  );
}

const projectTitleStyle: React.CSSProperties = {
  height: "1.5rem",
  textShadow: "#000000 1px 1px",
  color: "white",
  lineClamp: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  fontSize: "0.85rem",
  fontWeight: "lighter",
};

const projectTitleStyleMobile: React.CSSProperties = {
  height: "1.2rem",
  textShadow: "#000000 1px 1px",
  color: "white",
  lineClamp: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  fontSize: "0.65rem",
  fontWeight: "lighter",
};

function openVideoInNewTab(url: string) {
  const html = `<!DOCTYPE html><body style="background: #141718"><video src="${url}" autoplay controls/></body></html>`;
  const w = window.open("", "_blank");
  w?.document.write(html);
}

function download(url: string) {
  window.open(url, "_blank");
  mixpanel.track("Download video", {
    video: url,
  });
}

function LoadingCard({ musics }: { musics: any }) {
  const date = new Date().toLocaleDateString();

  const handleClick = () => {
    if (musics) {
      toast.error(
        "For this card the preview video page is available only when audio has finished processing",
        { duration: 5000 }
      );
    } else {
      return;
    }
  };

  return (
    <div
      className="flex-column rounded border text-center p-1 player-closed"
      onClick={handleClick}
    >
      <p className="m-0 p-0 small">{date}</p>

      <Spinner />
      <div className="m-0 p-0">
        <p style={{ fontSize: "0.65rem" }}>PROCESSING</p>
      </div>
      <ProgressBar
        striped
        animated
        variant="success"
        max={16}
        now={3}
        label={"VIDEO_BUILD_START"}
        style={{ width: "100%" }}
      />
    </div>
  );
}

function formatTime(sec: number) {
  const m = Math.floor(sec / 60);
  const s = Math.floor(sec % 60);
  return ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
}
